.logo {
    text-decoration: none;
    height: 6rem;
    width: 8rem;
    background-image: url('https://oichat.s3.us-east-2.amazonaws.com/assets/oi_dynamics.png');
    align-self: flex-start;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.extra_ctr{
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    background-color: white;
}
.light_btn{
    background-color: white;
    color:black;
    border:1px solid black;
}

.img {
    height:0;
    width:90%;
    padding-bottom:90%;
    background-image: url('https://oichat.s3.us-east-2.amazonaws.com/assets/img.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.services {
    height:16rem;
    width:16rem;
    background-size: cover;
    background-position: center;
}
.demo_img, .demo_phone, .left, .right{
    height:0;
    width:70%;
    padding-bottom: 70%;
    background-image: url('https://oichat.s3.us-east-2.amazonaws.com/assets/iPhone+12+Pro.png');
    opacity: 1;
    transform: scale(1.2);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: 2s infinite alternate moveup;
}
.tile {
    background-color: white;
    border-radius: 10px;

}
.icons {
    height:5rem;
    width:5rem;
    background-size: contain;
    background-position:center;
    background-repeat:no-repeat;
}
@media screen and (max-width:992px){
    .heading{
        font-size: 2em;
    }
    .heading2{
        font-size: 2em;
    }
    .left,.right{
        width:100%;
        padding-bottom:100%;
        transform: scale(1)
    }
}